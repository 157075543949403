body {
    font-family: 'Lato', sans-serif;
  }
  
  .container-privacidad {
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .container-privacidad li {
    list-style: circle;
    font-size: 1.7rem;
  }
  
  .header-privacidad {
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header-privacidad h1 {
    margin: 0;
    color: white;
  }
  
  .section-title-privacidad {
    color: #555;
  }
  
  .section-title-privacidad {
    font-family: "Lato", sans-serif;
    font-size: 3rem;
    padding: 20px 0;
  }
  
  .section-content-privacidad {
    color: #666;
    line-height: 1.6;
  }
  
  .section-list-privacidad {
    padding-left: 20px;
  }
  