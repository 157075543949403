/* services.css adjustments */
#services {
  padding: 30px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: #fff;
}

#services h2 {
  color: #fff;
  font-family: "Lato", sans-serif;
}

#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

#services h3 {
  font-weight: 700;
  padding: 5px 0;
  color: #fff;
  font-family: "Lato", sans-serif;
}

#services p {
  color: rgba(255, 255, 255, 0.75);
  font-family: "Lato", sans-serif;
}

#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

@media (max-width: 768px) {
  #services .section-title h2 {
    font-size: 28px;
  }

  #services .section-title p {
    font-size: 16.5px;
  }

  #services .fa {
    font-size: 30px;
  }
}