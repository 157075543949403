/* Agrega este estilo para el botón deshabilitado */
.btn-enviar button.disabled {
    background-color: #ffca2c;
    opacity: 0.6;
    cursor: not-allowed;
}

/* Resto de los estilos existentes */
h2 {
    font-size: 3rem;
    font-weight: 780;
    font-family: "Railway", sans-serif;
}

button {
    font-weight: 600;
}

.intro-create {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    border-bottom: 2px solid #a0a0a08a;
}

.overlay-create {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-top: 100px;
    margin-bottom: 20px;
}

.container-titulo {
    display: flex;
    flex-direction: column;
}

.container-titulo h1 {
    font-size: 5rem;
    font-weight: 700;
    font-family: "Railway", sans-serif;
    color: white;
}

.container-titulo h2 {
    font-size: 3rem;
    font-weight: 600;
    font-family: "Railway", sans-serif;
    color: white;
    text-transform: none;
}

/* .flex-container-create {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: auto;
    gap: 10px;
    text-align: center;
    margin: 20px 26%;
    border-radius: 10px;
    padding: 10px;
} */

.flex-container-create {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Estilos para el primer elemento que ocupará el 50% */
.flex-item1 {
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    box-sizing: border-box;
    width: 50%;
}

/* Contenedor para los dos elementos apilados */
.flex-column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

/* Estilos para los otros dos elementos */
.flex-item2,
.flex-item3 {
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    margin: 10px;
    box-sizing: border-box;
    flex: 1;
}


/* .flex-item {
    padding: 10px;
    color: black;
    border-radius: 10px;
    width: 100%;
    background-color: white;
} */

.flex-item1 h2,
.flex-item2 h2,
.flex-item3 h2 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
}

.flex-item1 h3,
.flex-item2 h3,
.flex-item3 h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.origenYDestino {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    text-align: left;
}


.dias-personas-budget {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px 30px;
}

.budget {
    margin-right: auto;
    text-align: center;
}

.dias input {
    margin-top: 10px;
}

.budget {
    margin-left: auto;
    text-align: center;
}

.budget-titulos-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.budget-titulos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 2px;
    z-index: 1;
    margin: 3px;
}

.budget-information-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    margin-right: 2%;
}

.budget-info {
    width: 20px;
    height: 20px;
    background-color: #4395ff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: bold;
}

.dias {
    text-align: left;
    margin-bottom: 10px;
}


.dias input,
.personas input {
    height: 42%;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    font-size: 1.8rem;
}

.btn-budget {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    /* Ajusta el espacio entre botones */
    width: 100%;
}

.budget button {
    background-color: white;
    color: #0066ee;
    border: 2px solid #0066ee;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
}

.interests-options button {
    background-color: white;
    color: #0066ee;
    border: 2px solid #0066ee;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    width: auto;
}

.personas-options button {
    background-color: white;
    color: #0066ee;
    border: 2px solid #0066ee;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    width: auto;
}

.btn-budget button.selected,
.interests-options button.selected {
    background-color: #0066ee;
    color: white;
}

.btn-budget button:hover,
.interests-options button:hover {
    background-color: #0066ee;
    color: white;
}

.interests-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.requisitos-alojamiento {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.requisitos-options-btn {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    /* Ajusta el espacio entre botones */
    width: 100%;
}

.requisitos-alojamiento button {
    background-color: white;
    color: #0066ee;
    border: 2px solid #0066ee;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    width: auto;
}

.requisitos-options-btn button.selected,
.estrellas-options button.selected,
.personas-options button.selected {
    background-color: #0066ee;
    color: white;
}

.requisitos-options-btn button:hover,
.estrellas-options button:hover,
.personas-options button:hover {
    background-color: #0066ee;
    color: white;
}

.salto-linea {
    background: #0066ee;
}

.necesidades-especiales {
    text-align: left;
    width: 100%;
}

.necesidades-especiales textarea {
    width: 100%;
}

.requisitos-transporte {
    margin-top: 20px;
}

.tipo-transporte {
    margin-top: 10px;
}

.checkbox-transporte {
    padding: 100px;
}

.requisitos-transporte-cosa {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}

.transporte-options-btn button,
.preferencia-ubicacion-btn button {
    background-color: white;
    color: #0066ee;
    border: 2px solid #0066ee;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
    width: auto;
}

.transporte-options-btn button.selected,
.preferencia-ubicacion-btn button.selected {
    background-color: #0066ee;
    color: white;
}

.transporte-options-btn button:hover,
.preferencia-ubicacion-btn button:hover {
    background-color: #0066ee;
    color: white;
}

.btn-enviar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 30px;
}

.btn-enviar button {
    background-color: #ffca2c;
    color: black;
    border: 1px solid #ffca2c;
    padding: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
    width: auto;
    font-size: 1.7rem;
    font-family: "Railway", sans-serif;
}

.btn-enviar button.selected {
    background-color: #ffca2c;
    color: black;
}

.btn-enviar button:hover {
    background-color: #ffca2c;
    color: black;
    border: 1px solid white;
}

@media (max-width: 1100px) {
    .origenYDestino {
        grid-template-columns: repeat(1, 1fr);
    }

    .dias-personas-budget {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .container-titulo h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    .container-titulo h2 {
        font-size: 2rem;
    }

    .requisitos-alojamiento {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 620px) {
    .dias-personas-budget {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 576px) {
    .container-titulo h1 {
        font-size: 2.5rem;
    }

    .container-titulo h2 {
        font-size: 1.5rem;
    }

    .requisitos-alojamiento {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 380px) {
    .container-titulo h1 {
        font-size: 2rem;
    }

    .container-titulo h2 {
        font-size: 1rem;
    }
}



/* AQUI VIENE LO DE PACO */
/* Hacer los elementos flexibles y que se apilen en pantallas pequeñas */
@media (max-width: 768px) {
    .flex-container-create {
        flex-direction: column;
    }

    .flex-item1,
    .flex-column,
    .flex-item2,
    .flex-item3 {
        width: 90vw;
        
    }
}