.profile {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 81px;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    
  }
  
  .profile-details {
    margin-bottom: 20px;
  }

    
  .profile-logout {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #bb2d3b;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .profile-logout:hover {
    background-color: white;
    color: #bb2d3b;
    border: 1px solid #bb2d3b;
  }
  
  @media (max-width: 768px) {
  
    .content > div {
      margin-bottom: 20px; /* Espacio entre los apartados */
    }
  }
  