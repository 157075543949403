.close-activity-details {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
}

.close-activity-details button {
  background-color: white;
  border: none;
  border-radius: 50px;
}

.close-activity-details span {
  padding: 6px 4px;
  font-size: 1.75rem;
  color: black
}

.activity-details-container {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.activity-details-container h2 {
  font-size: 2.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  padding-top: 10px;
  margin: 0;
}

.activity-details-container p {
  font-size: 1.4rem;
  margin: 5px 0;
}

.booking-options {
  margin-top: 10px;
}

.booking-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #92ceff91;
  color: white;
  border: 1px solid #005da8;
  padding: 0px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  width: 150px; /* Asegúrate de que el ancho y alto son correctos para la imagen */
  height: 30px; /* Cambié el alto para dar más espacio a la imagen */
  transition: transform 0.3s ease-in-out;
}

.booking-button:hover {
  background-color: #209aff91;
  border: 1px solid #005da8; 
  transition: background-color 0.3s ease, border-color 0.3s ease;
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1);
}

.booking-button img:hover {
  filter: brightness(1.2);
}


.booking-btns {
  display: inline-flex; /* Use inline-flex en lugar de inline-block */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  overflow: hidden;
  padding: 15px
}

.booking-button img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%; /* Asegúrate de que la imagen no se desborde */
  max-height: 100%; /* Asegúrate de que la imagen no se desborde */
  object-fit: contain; /* Ajusta la imagen para que se mantenga dentro de los límites del botón */
}

.btn-viator img {
  height: 20px;
}


.activity-tips {
  margin-top: 10px;
}


.activity-tips ul {
  list-style-type: disc;
  margin-left: 20px;
}

.activity-tips ul li {
  font-size: 1.4rem;
  margin: 5px 0;
}

.btn-activity-details-gm {
  background-color: white;
  border: 1px solid #b6b6b6cb;
  padding: 4px 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.btn-activity-details-gm:hover {
  transform: translateY(-10px);
}

.btn-activity-details-gm span {
  font-size: 1.5rem;
  color: black;
  font-weight: 600;
}

.activity-details-links span {
  font-size: 1.7rem;
  color: black;
}


@media (max-width: 500px) {
  .close-activity-details button {
    padding: 4px 8px;
  }
}