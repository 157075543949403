/* trip.css */

.mytrips-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mytrips-card {
    position: relative;
    width: 100%;
    padding-top: 100%; /* Mantiene la proporción de la tarjeta */
    overflow: hidden;
    border-radius: 10px;
    background-color: #f0f0f0;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mytrips-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    cursor: pointer;
}

/* Mantén los estilos existentes */
.mytrips-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid black;
    border-radius: 10px;
}

/* Otros estilos existentes */


.mytrips-avatar, .mytrips-avatar2, .mytrips-avatar3 {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
    border: 1px solid rgb(222, 222, 222);
    padding: 5px 10px;
    z-index: 1;
    margin: 5px;
}

.mytrips-avatar4 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
    border: 1px solid rgb(222, 222, 222);
    padding: 2px;
    z-index: 1;
    margin: 3px;
}

.mytrips-avatar {
    top: 10px;
    left: 10px;
}

.mytrips-avatar2 {
    bottom: 10px;
    left: 10px;
}

.mytrips-avatar3 {
    bottom: 10px;
    right: 10px;
}

.mytrips-avatar4 {
    top: 10px;
    right: 10px;
}

.mytrips-card-actions {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    z-index: 2;
}

.mytrips-card-action-dislike, .mytrips-card-action-like {
    width: 50px; /* Ajusta el tamaño del botón */
    height: 50px; /* Ajusta el tamaño del botón */
    border-radius: 50%; /* Hace que el botón sea circular */
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
}

.mytrips-card-action-dislike {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff4c4c;
}

.mytrips-card-action-like {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4caf50;
}

.mytrips-card-action-dislike span, .mytrips-card-action-like span {
    font-size: 2rem;
}

.mytrips-card-action-dislike:hover {
    background-color: #ff1a1a;
    color: white;
}

.mytrips-card-action-like:hover {
    background-color: #45a049;
    color: white;
}

.avatar-initials {
    width: 30px;
    height: 30px;
    background-color: #b7c0ca;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 10px;
}
.avatar-initials-info {
    width: 40px;
    height: 40px;
    background-color: #4395ff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bold;
}

.avatar-username {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
}

.mytrips-card-text {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.mytrips-card-text h2 {
    font-size: 2rem;
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    text-transform: none;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #608dfd;
    
}

@media (max-width: 1200px) {
    .mytrips-card-text h2 {
        font-size: 1.7rem;
    }

    .mytrips-avatar, .mytrips-avatar2, .mytrips-avatar3 {
        padding: 3px 7px;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .mytrips-avatar {
        top: 5px;
        left: 5px;
        padding: 3px 7px;
        font-size: 1rem;
    }

    .mytrips-avatar2 {
        bottom: 5px;
        left: 5px;
        padding: 3px 7px;
        font-size: 1rem;
    }

    .mytrips-avatar3 {
        bottom: 5px;
        right: 5px;
        padding: 3px 7px;
        font-size: 1rem;
    }

    .mytrips-avatar4 {
        margin: 0;
        top: 5px;
        right: 5px;
    }

    .avatar-initials {
        width: 25px;
        height: 25px;
        font-size: 1.4rem;
    }
    .avatar-initials-info {
        width: 40px;
        height: 40px;
        font-size: 1.4rem;
    }

    .avatar-username {
        font-size: 1.3rem;
    }

    .mytrips-card-actions {
        bottom: 5px;
    }
}

/* Clase adicional para swipeMode */
.swipe-mode {
    margin-bottom: 60px;
}

.fade.show.tooltip.bs-tooltip-end {
    opacity: 1 !important;
    visibility: visible !important;
}

.tooltip {
    opacity: 1;
    visibility: visible;
    display: block !important;

}

.tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8); /* Color de fondo del tooltip */
    color: #fff; /* Color del texto del tooltip */
    padding: 5px 10px; /* Espaciado interno del tooltip */
    border-radius: 4px; /* Bordes redondeados del tooltip */
}

.tooltip-inner p {
    font-size: 1.4rem;
}

