/* trips.css */

.mytrips-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    text-align: center;
}

.mytrips-overlay {
    background: rgba(0, 0, 0, 0.0);
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.mytrips-encabezado {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.mytrips-encabezado h1 {
    font-size: 4rem;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    color: black;
}

.mytrips-encabezado h2 {
    font-size: 1.7rem;
    font-family: "Lato", sans-serif;
    color: rgba(0, 0, 0, 0.342);
    text-transform: none;
    margin: 10px 5% 10px 5%;
    text-align: center;
}

.mytrips-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin-top: 40px;
}

.mytrips-list {
    display: contents; /* Permite que los elementos de la lista se comporten como si no estuvieran dentro de una lista */
}

.mytrips-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alternativa-crearViaje button {
    background-color:#ffca2c;
    color: black;
    border: 1px solid #ffca2c;
    padding: 5px 20px;
    border-radius: 10px;
    cursor: pointer;
    width: auto;
    font-size: 1.7rem;
    font-family: "Lato", sans-serif;
}

.alternativa-crearViaje button.selected {
    background-color: #ffca2c;
    color: black;
}

.alternativa-crearViaje button:hover {
    background-color: #ffca2c;
    color: black;
    border: 1px solid white;
}

.alternativa-noHayViajes h2{
    font-size: 2rem;
    font-family: "Lato", sans-serif;
    color: rgba(0, 0, 0, 0.342);
    text-transform: none;
}

@media (max-width: 1200px) {
    .mytrips-encabezado h1 {
        font-size: 3rem;
    }

    .mytrips-cards-container {
        grid-template-columns: repeat(2, 1fr);
        max-width: 800px;
    }
}

@media (max-width: 800px) {

    .mytrips-encabezado h2 {
        font-size: 1.8rem;
    }

    .mytrips-cards-container {
        grid-template-columns: 1fr;
        max-width: 400px;
    }
}

@media (max-width: 400px) {

    .mytrips-encabezado h2 {
        font-size: 1.5rem;
    }

    .mytrips-cards-container {
        max-width: 280px;
    }
}