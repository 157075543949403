.airplane-container {
  position: relative;
  top: 10%;
  left: 1%;
  transform: translate(0, -50%);
  width: 100%;
  height: 2px;
  z-index: 2;
}

.airplane {
  position: absolute;
  top: 0;
  left: -20px; /* Ajusta según el tamaño de tu avión */
  font-size: 3rem; /* Ajusta según el tamaño de tu avión */
  transition: transform 0.5s ease-out; /* Transición más suave */
}

.clouds {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2rem;
}
