#contact {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;
  padding: 100px 0 60px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: rgba(255, 255, 255, 0.75);
}

.container-form {
  display: block;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.section-title h2 {
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 50px;
  font-family: "Lato", sans-serif;
}

#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-family: "Lato", sans-serif;
}

.contact-subtitle {
  color: white;
}


#contact h3 {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  font-size: 2.3rem;
  font-family: "Lato", sans-serif;

}

#success {
  display: flex;
  justify-content: center;
  align-items: center;

}

#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}

#contact .btn-custom:hover {
  color: #1f386e;
  background: #fff;
}

label {
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  float: left;
}

#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}

.form-control::-webkit-input-placeholder {
  color: #777;
}

.form-control:-moz-placeholder {
  color: #777;
}

.form-control::-moz-placeholder {
  color: #777;
}

.form-control:-ms-input-placeholder {
  color: #777;
}

#contact .contact-item {
  margin: 20px 0;
}

#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}

#contact .contact-item i.fas {
  margin-right: 10px;
  color: white;
}

#contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px;
  text-align: center;
}

#contact .social ul li {
  display: inline-block;
  margin: 0 20px;
}

#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
}

.contact-item a {
  color: #fff;
  text-decoration: underline;
}

.contact-item a:hover {
  cursor: pointer;
}

.overlay-alerta, .overlay-alerta h2 {
  color: black !important;
}
  
  @media (max-width: 768px) {
    #contact .section-title h2 {
      font-size: 28px;
    }
  
    #contact .section-title p {
      font-size: 16.5px;
    }
  
    #contact .fa {
      font-size: 30px;
    }
  }
  