.overlay-error-creando {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que el overlay esté en el frente */
}

.overlay-error-creando-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.overlay-error-creando-content h2 {
    margin-bottom: 20px;
}

.overlay-error-creando-content p {
    margin-bottom: 20px;
}

.overlay-error-creando-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.overlay-error-creando-content button:hover {
    background-color: #0056b3;
}

.no-scroll {
    overflow: hidden;
}
