.logo {
  display: none;
  position: relative; /* Hace que el posicionamiento absoluto del hijo se base en este contenedor */
}

.logo img {
  position: absolute;
  margin-top: 5%;
  margin-left: 5%; 
  width: 20%; 
  height: auto; 
}

.container-backleft {
  display: flex;
}

.container-overlay-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Cambiado de flex-start a center */
  position: absolute;
  background: url('./prueba-login.webp') center center no-repeat;
  background-size: cover;
  top: 5vh;
  left: 5vw;
  height: 90vh;
  width: 40vw;
  border-radius: 10px;
  padding: 20px;
}


.container-img-left {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}


.container-titulo-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.slogan {
  font-size: 2rem;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.titulo {
  font-size: 4rem;
  font-weight: 500;
  color: #fff;
  font-family: "Lato", sans-serif;
}


.container-backright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  ;
  color: #333;
  /* Color del texto */
  text-align: center;
  /* Alineación del texto */
  position: absolute;
  width: 40vw;
  height: 90vh;
  top: 5vh;
  right: 5vw;
  border-radius: 20px;
}

.container-titulo-right {
  display: flex;
  justify-content: center;
  font-size: 24px;
  /* Tamaño del título */
  margin-bottom: 20px;
  /* Espacio debajo del título */
  top: 0;
}

.titulo-right {
  font-size: 4rem;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
  gap: 0px;
  /* Espacio entre elementos del formulario */
}

/* Estilo del contenedor del formulario */
.form-group {
  position: relative;
  margin-bottom: 1.5em;
}

/* Estilo de la etiqueta */
.form-group label {
  position: absolute;
  top: 50%;
  left: 22%;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  background: #fff;
  padding: 0 5px;
  transform: translateY(-50%);
  font-family: "Lato", sans-serif;
}

/* Estilo del campo de entrada */
.form-group input {
  width: 60%;
  padding: 1rem 0.5em 0.5em 0.5em;
  border: 1px solid #ccc;
  border-radius: 0.25em;
  background: none;
  /* Asegúrate de que el fondo sea transparente */
  box-sizing: border-box;
  /* Para incluir el padding y el borde en el tamaño total */
  font-size: 1.5rem;
}

/* Movimiento de la etiqueta cuando el campo está enfocado o tiene un valor */
.form-group input:focus+label,
.form-group input:not(:placeholder-shown)+label {
  top: 0;
  left: 20%;
  font-size: 0.75em;
  color: #333;
  transform: translateY(-50%) translateX(5px);
  background: #fff;
  padding: 0 5px;
}

/* Contenedor del campo de contraseña */
.form-group-password {
  position: relative;
}

/* Campo de entrada del campo de contraseña */
.form-group-password input {
  padding-right: 2.5em;
  /* Espacio para el icono */
  font-family: "Lato", sans-serif;
}

/* Icono de mostrar/ocultar contraseña */
.form-group-password .toggle-password {
  position: absolute;
  top: 50%;
  right: 23%;
  transform: translateY(-50%);
  cursor: pointer;
  padding-left: 5px;
}


.forgot-password {
  color: #0066ee;
  /* Color del enlace 'Olvidé mi contraseña' */
  cursor: pointer;
  /* Cambia el cursor a una mano cuando se pasa por encima */
  text-align: right;
  /* Alinea el texto a la derecha */
  margin-right: 8vw;
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 20%;
  width: 60%;
}

.signin-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centra los elementos de acción de inicio de sesión */
  gap: 10px;
  /* Espacio entre los elementos de acción de inicio de sesión */

}

.signin-btn {
  background-color: #0066ee;
  /* Color de fondo del botón de inicio de sesión */
  color: white;
  /* Color del texto del botón */
  border: none;
  /* Elimina el borde del botón */
  padding: 10px 20px;
  /* Espaciado interno del botón */
  border-radius: 10px;
  /* Bordes redondeados del botón */
  width: 60%;
  cursor: pointer;
  /* Cambia el cursor a una mano cuando se pasa por encima */
  
}


.or-separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 64%;
  /* Asegúrate de que el contenedor ocupe todo el ancho disponible */
}

.or-separator::before,
.or-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #7a7a7a6f;
  /* Confirma que el color sea visible contra el fondo */
  margin: 0 10px;
  /* Asegura un espaciado adecuado */

}

.or-separator span {
  white-space: nowrap;
  /* Evita que el texto 'or' se divida en líneas */
  color: #7a7a7a6f;
}

.google-signin {
  display: flex;
  align-items: center;
  /* Centra verticalmente el texto y la imagen de Google */
  gap: 10px;
  /* Espacio entre la imagen de Google y el texto */
  cursor: pointer;
  /* Cambia el cursor a una mano cuando se pasa por encima */
  width: 60%;
  margin-bottom: 3%;
}

.signin-btn-google {
  background-color: none;
  background: transparent;
  color: #608dfd;
  border: 1px solid #608dfd;
  /* Borde del botón de Google */
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  padding: 5px 2px 5px 2px;
}

.logo-google-login {
  padding-right: 10px;
}

.signin-span-google {
  font-size: 1.5rem;
}

.signin-btn-google:hover {
  background-color: none;
  background: transparent;
  color: #608dfd;
  border: 1px solid #608dfd;
  /* Borde del botón de Google */
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  padding: 5px 2px 5px 2px;
}

.signup-prompt {
  color: #333;
  /* Color del texto */
  text-align: center;
  /* Alineación del texto */
  font-family: "Lato", sans-serif;
}

.signup-link {
  color: #0066ee;
  /* Color del enlace 'Registrarse' */
  cursor: pointer;
  /* Cambia el cursor a una mano cuando se pasa por encima */
  font-family: "Lato", sans-serif;
}


@media screen and (max-width: 992px) {
  .logo {
    display: block;
  }

  .logo img {
    width: 14%;
  }
  .container-backleft {
    display: none;
  }

  .container-backright {
    width: 80vw;
    /* Ajusta el ancho */
    top: 2vh;
    /* Ajusta la posición superior */
    right: 10vw;
    /* Ajusta la posición derecha para centrar más el contenedor */
    padding: 15px;
    /* Reduce el padding para dar más espacio al contenido */
    border-radius: 10px;
    /* Suaviza los bordes */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .titulo-right {
    font-size: 4rem;
  }

  /* Estilo del campo de entrada */
  .form-group input {
    width: 100%;
    padding: 1rem 0.5em 0.5em 0.5em;
    border: 1px solid #ccc;
    border-radius: 0.25em;
    background: none;
    /* Asegúrate de que el fondo sea transparente */
    box-sizing: border-box;
    /* Para incluir el padding y el borde en el tamaño total */
    font-size: 1.5rem;
  }

  /* Estilo de la etiqueta */
  .form-group label {
    position: absolute;
    top: 50%;
    left: 2%;
    color: #999;
    pointer-events: none;
    transition: all 0.2s ease;
    background: #fff;
    padding: 0 5px;
    transform: translateY(-50%);
  }

  /* Movimiento de la etiqueta cuando el campo está enfocado o tiene un valor */
  .form-group input:focus+label,
  .form-group input:not(:placeholder-shown)+label {
    top: 0;
    left: 2%;
    font-size: 0.75em;
    color: #333;
    transform: translateY(-50%) translateX(5px);
    background: #fff;
    padding: 0 5px;
  }

  .forgot-password {
    color: #0066ee;
    /* Color del enlace 'Olvidé mi contraseña' */
    cursor: pointer;
    /* Cambia el cursor a una mano cuando se pasa por encima */
    text-align: right;
    /* Alinea el texto a la derecha */
    margin-right: 0;
  }

  .signin-btn {
    background-color: #0066ee;
    /* Color de fondo del botón de inicio de sesión */
    color: white;
    /* Color del texto del botón */
    border: none;
    /* Elimina el borde del botón */
    padding: 10px 20px;
    /* Espaciado interno del botón */
    border-radius: 10px;
    /* Bordes redondeados del botón */
    width: 100%;
    cursor: pointer;
    /* Cambia el cursor a una mano cuando se pasa por encima */
  }

  .or-separator {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    /* Asegúrate de que el contenedor ocupe todo el ancho disponible */
  }

  .google-signin {
    display: flex;
    align-items: center;
    /* Centra verticalmente el texto y la imagen de Google */
    gap: 10px;
    /* Espacio entre la imagen de Google y el texto */
    cursor: pointer;
    /* Cambia el cursor a una mano cuando se pasa por encima */
    width: 100%;
  }

  .signin-btn-google {
    margin-bottom: none;
    padding: 8px 5px 8px 5px;
  }

  .signin-btn-google:hover {
    margin-bottom: none;
    padding: 8px 5px 8px 5px;
  }

  /* Icono de mostrar/ocultar contraseña */
  .form-group-password .toggle-password {
    position: absolute;
    top: 50%;
    right: 6%;
    transform: translateY(-50%);
    cursor: pointer;
    padding-left: 5px;
  }

  .alert {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    width: 100%;
  }
}