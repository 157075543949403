/* features.css adjustments */
#features {
  background: #f6f6f6;
  padding: 30px 0px;
  padding-bottom: 70px;
}
  
  #features .section-title h2 {
    font-size: 36px;
    margin-bottom: 20px;
    margin-top: 50px;
    font-family: "Lato", sans-serif;
  }
  
  #features .section-title p {
    font-size: 18px;
    margin-bottom: 40px;
    font-family: "Lato", sans-serif;
  }

  #features h3 {
    font-weight: 700;
    padding: 5px 0;
    font-family: "Lato", sans-serif;
  }
  
  #features i.fa {
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    color: #fff;
    width: 100px;
    height: 100px;
    padding: 30px 0;
    border-radius: 50%;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    #features .section-title h2 {
      font-size: 28px;
    }
  
    #features .section-title p {
      font-size: 14px;
    }
  
    #features .fa {
      font-size: 30px;
    }
  }
  