body {
    font-family: 'Lato', sans-serif;
  }
  
  .container-terminos-condiciones {
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .header-terminos-condiciones {
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    padding: 20px;
    text-align: center;
  }
  
  .header-terminos-condiciones h1 {
    margin: 0;
    color: white;
  }
  
  .section-title {
    color: #555;
  }

  .section-title {
    font-family: "Lato", sans-serif;
    font-size: 3rem;
    padding: 20px 0;
  }
  
  .section-content {
    color: #666;
    line-height: 1.6;
  }
  
  .section-list {
    padding-left: 20px;
  }
  