.reset_ok-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.logo-reset_ok {
    position: absolute;
    left: 15px;
    top: 15px;
}

.logo-reset_ok img {
    width: 240px;
    height: 160px;
    object-fit: fill;
}

.reset_ok-body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.reset_ok-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.reset_ok-header img {
    width: 200px;
    height: 200px;
}

.verified-denied-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
}

.reset_ok-header h1 {
    font-size: 40px;
    font-weight: 700;
    color: black;
    margin-bottom: 20px;
    font-family: "Lato", sans-serif;
    text-align: center;
}

.reset_ok-header p {
    font-size: 18px;
    font-weight: 400;
    color: black;
    margin-bottom: 20px;
    font-family: "Lato", sans-serif;
    text-align: center;
}

@media (max-width: 900px) {
    .logo-reset_ok img {
        width: 160px;
        height: 120px;

    }

    .reset_ok-body {
        margin-top: 150px
    }

    .reset_ok-header img {
        width: 100px;
        height: 100px;
    }

    .reset_ok-header h1 {
        font-size: 35px;
        font-weight: 700;
        color: black;
        margin-bottom: 20px;
        font-family: "Lato", sans-serif;
        text-align: center;
    }

    .reset_ok-header p {
        font-size: 18px;
        font-weight: 400;
    }
    .verified-denied-input {
        width: 50%;
    }
}