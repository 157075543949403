.explore-destinations-container {
    display: flex;
    width: 100%;
    height: 100vh;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    top: 100px;
}

.explore-encabezado {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.explore-encabezado h1 {
    font-size: 4rem;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    color: black;
}

.explore-encabezado h2 {
    font-size: 1.7rem;
    font-family: "Lato", sans-serif;
    color: rgba(0, 0, 0, 0.342);
    text-transform: none;
    margin: 10px 5% 10px 5%;
    text-align: center;
}

.explore-swipe {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    z-index: 1;
}

.travel-swipe {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    min-height: 100vh; 
    position: relative;
}

.travel-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 50%;
    width: 28%;
    text-align: center;
    transition: transform 0.5s ease;
    position: absolute;
    will-change: transform; /* Optimiza la animación */
    z-index: 2;
    border-radius: 10px;
    margin-top: 30px
}

.travel-card img {
    max-width: 100%;
    height: 100%;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
}

.liked-travels {
    margin-top: 32px;
}

.liked-travels ul {
    list-style-type: none;
    padding: 0;
}

@media (max-width: 1200px) {
    .travel-card {
        width: 40%;
    }
}

@media (max-width: 1000px) {
    .travel-card {
        width: 50%;
    }
}

@media (max-width: 800px) {
    .travel-card {
        width: 55%;
    }
}

@media (max-width: 600px) {
    .travel-card {
        width: 60%;
    }
}

@media (max-width: 400px) {
    .travel-card {
        width: 65%;
    }
}

@media (max-width: 320px) {
    .travel-card {
        width: 70%;
    }
}
