/* src/LoadingPage.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f0f0;
}

.plane {
    width: 100px;
    height: 100px;
}

.plane-edges {
    stroke-dasharray: 2223;
    stroke-dashoffset: 2223;
    animation: 6.5s linear 0s infinite normal forwards running dash;
}

.espacio-loading {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 44px;
}

.espacio-loading h1 {
    font-family: "Lato", sans-serif;
}

.puntos-animados-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 10px;

}

.puntos {
    width: 6px;
    height: 6px;
    background-color: gray;
    border-radius: 50%;
    animation: 0.6s ease 0s infinite alternate none running saltos;
    margin-left: 3px;
    margin-right: 3px;
}

@keyframes saltos {
    100% {
        opacity: 0.5;
        transform: translate3d(0px, -0.5rem, 0px);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 0;
    }

    40% {
        stroke-dashoffset: 2223;
    }

    80% {
        stroke-dashoffset: 4446;
        fill: transparent;
    }

    95% {
        stroke-dashoffset: 4446;
        fill: rgb(255, 255, 255);
    }

    100% {
        stroke-dashoffset: 4446;
        fill: transparent;
    }
}