@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Tu CSS existente */
.top-liked-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.top-liked-overlay {
    background: rgba(0, 0, 0, 0.0);
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.top-liked-encabezado {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
}

.top-liked-encabezado h1 {
    font-size: 3rem;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    color: black;
}

.top-liked-encabezado h2 {
    font-size: 1.85rem;
    font-family: "Lato", sans-serif;
    color: rgba(0, 0, 0, 0.342);
    text-transform: none;
    margin: 10px 5% 0 5%;
    text-align: center;
}

.slick-slider {
    width: 100%;
    max-width: 1200px;
}

.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 5px;
}

.top-liked-list-item {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: transparent !important;
}
