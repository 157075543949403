.profile-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  max-width: 768px;
}

.profile-details h2 {
  font-family: "Lato", sans-serif;
}

.profile-details form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-details .form-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.form-group-forgot-password {
  justify-content: left;
}

.profile-details label {
  margin-bottom: 5px;
  font-weight: bold;
}

.profile-details input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.profile-details button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  max-width: 400px;
}

.profile-details-guardar-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-details button:hover {
  background-color: #0056b3;
}

/* Estilo de la etiqueta */
.profile-details-form label {
  position: absolute;
  top: 50%;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease;
  background: #fff;
  padding: 0 5px;
  transform: translateY(-50%);
}

.profile-details-form input {
  max-width: 400px;
  width: 100%;
}

/* Movimiento de la etiqueta cuando el campo está enfocado o tiene un valor */
.profile-details-form input:focus+label,
.profile-details-form input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 0.75em;
  color: #333;
  transform: translateY(-50%) translateX(5px);
  background: #fff;
  padding: 0 5px;
}

.alert-mandatory-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  left: 0;
}

/* Estilos para la tabla de dislikes */
.dislikes-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.dislikes-table th,
.dislikes-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.dislikes-table th {
  background-color: #f2f2f2;
}


@media (max-width: 870px) {
  .profile-details {
    max-width: 400px;
  }

  .dislikes-table,
  .dislikes-table thead,
  .dislikes-table tbody,
  .dislikes-table th,
  .dislikes-table td,
  .dislikes-table tr {
    display: block;
  }

  .dislikes-table thead tr {
    display: none;
  }

  .dislikes-table tr {
    margin-bottom: 15px;
  }

  .dislikes-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .dislikes-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 10px;
    font-weight: bold;
    text-align: left;
  }

  .dislikes-table td:last-child {
    border-bottom: 2px solid #ddd;
  }

  .profile-details-form label {
    left: 2%;
  }

  /* Movimiento de la etiqueta cuando el campo está enfocado o tiene un valor */
  .profile-details-form input:focus+label,
  .profile-details-form input:not(:placeholder-shown)+label {
    left: 2%;
  }
}