#about {
  padding: 30px 0;
}

#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
  font-family: "Lato", sans-serif;
}

.section-title {
  margin-bottom: 0;
}

#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}

#about .about-text li:before {
  color: #5ca9fb;
  font-family: "FontAwesome";
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}

#about img {
  width: 520px;
  border-right: 0;
}

.container-imagen {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

#about p {
  line-height: 24px;
  margin: 30px 0;
  font-family: "Lato", sans-serif;
}