.forgot-pwd-container-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.logo-forgot-pwd {
    position: absolute;
    top: 40px;
    left: 40px;
}

.back-button-forgot-pwd {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    color: black;
    border: 1px solid black;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 2;
}

.back-button-forgot-pwd p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Lato", sans-serif;
    padding-left: 5px;
    margin-top: -3px;
}

.back-button-forgot-pwd:hover {
    transform: scale(1.1);
}

.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.titulo-forgot-pwd {
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

.texto-forgot-pwd {
    margin-bottom: 20px;
    text-align: center;
    color: #555;
}

.form-group {
    margin-bottom: 15px;
    width: 100%;
}

.form-group-forgot-pwd input {
    width: 100%;
}

/* Estilo de la etiqueta */
.form-group-forgot-pwd label {
    position: absolute;
    top: 50%;
    left: 2%;
    color: #999;
    pointer-events: none;
    transition: all 0.2s ease;
    background: #f7f7f7;
    padding: 0 5px;
    transform: translateY(-50%);
  }

  /* Movimiento de la etiqueta cuando el campo está enfocado o tiene un valor */
  .form-group-forgot-pwd input:focus+label,
  .form-group-forgot-pwd input:not(:placeholder-shown)+label {
    top: 0;
    left: 2%;
    font-size: 0.75em;
    color: #333;
    transform: translateY(-50%) translateX(5px);
    background: #fff;
    padding: 0 5px;
  }

.label-forgot-pwd {
    display: flex;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
}

.input-email-forgot-pwd {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-enviar-forgot-pwd {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.btn-enviar-forgot-pwd:hover {
    background-color: #0056b3;
}

.message, .token-message {
    margin-top: 20px;
    color: green;
    text-align: center;
}

.alert-rp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    margin-top: 20px;
}


@media (max-width: 600px) {
    .logo-forgot-pwd {
        top: 20px;
        left: 20px;
    }

    .forgot-password-container{
        max-width: 300px;
    }

}