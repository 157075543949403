h2 {
    font-size: 2.6rem;
    font-weight: 400;
    font-family: "Fredoka", sans-serif;
    text-transform: none;
}

p {
    font-size: 1.8rem;
    font-weight: 300;
    font-family: "Lato", sans-serif;
}

.container-specific-trip {
    display: flex;
    max-width: 100%;
    position: relative;
}

.container-left-specific-trip {
    width: 55%;
    justify-content: center;
    align-items: center;
}

.container-right-specific-trip {
    position: fixed;
    right: 0;
    top: 0;
    width: 45%;
    height: 100%;
    transition: transform 0.5s ease;
}

.warning-map-error {
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 10px;
}

.icono-atencion {
    margin-right: 4px;
}

.cerrar-alerta-error-ubicacion {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close-map-button {
    position: fixed;
    top: 20px;
    right: 10px;
    padding: 10px 20px;
    font-size: 1.5rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2;
}

.close-map-button span {
    margin-right: 5px;
}

.back-button {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    color: white;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 2;
}

.back-button p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Lato", sans-serif;
    padding-left: 5px;
    margin-top: -3px;
}

.back-button:hover {
    border: 1px solid white;
}

.image-container {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    position: relative;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, transparent, rgba(0, 0, 0, 0.60) 80%);
    pointer-events: none;
}

.image-container h2 {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    color: white;
    font-size: 3.5rem;
    text-align: center;
    padding: 0 10px;
    z-index: 2;
    font-weight: 300;
    font-family: "Lato", sans-serif;
}

.trip-image {
    width: 100%;
    height: auto;
}

.trip-details {
    text-align: left;
    margin-top: 20px;
}

.trip-details h3 {
    margin-bottom: 15px;
}

.day-container {
    margin-top: 20px;
}

.day-container h4 {
    margin-bottom: 10px;
}

.activity {
    margin-top: 20px;
}

.activity h5 {
    margin-bottom: 5px;
}

.activity p {
    margin: 2px 0;
}

.activity-details h6 {
    margin: 10px 0 5px;
}

.activity-details ul {
    list-style-type: disc;
    margin: 5px 0 15px 20px;
}

.activity-details ul li {
    margin-bottom: 5px;
}

.transport-accommodation {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 50px;
    width: 100%;
}

.izquierda-transport {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 50%;
    border-right: 2px solid rgb(224 224 224);
    padding: 0 10px 0 10px;
}

.derecha-accommodation {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 50%;
    margin: 0 10px 0 10px;
}

.accommodation-avatares {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    font-family: "Lato", sans-serif;
    font-weight: 300;
}

.derecha-accommodation-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
    border: 1px solid rgb(222, 222, 222);
    padding: 5px 10px;
    z-index: 1;
    margin: 5px;
}

.derecha-accommodation-avatar a {
    text-decoration: underline;
}

.avatar-con-enlace:hover {
    transform: translateY(-4px);
}

.avatar-con-enlace2:hover {
    transform: translateY(-4px);
}

.avatar-con-enlace > a {
    cursor: pointer;
}

.avatar-con-enlace2 > a {
    cursor: pointer;
}

.avatar-username-clicable {
    cursor: pointer;
}

.salto-linea-responsive {
    display: none;
}

.container-origen-destino {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;
    width: 100%;
    padding: 0 40px;
}

.origen-nombre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titulo-dia {
    font-size: 3rem;
    font-weight: 300;
    font-family: "Lato", sans-serif;
    margin-top: 50px;
    padding: 20px;
    padding-bottom: 0;
}

.subtitulo-dia {
    font-size: 1.825rem;
    font-weight: 300;
    padding-left: 40px;
    font-family: "Lato", sans-serif;
}

.activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 20px 0px 20px;
    padding: 0px 5px 0px 5px;
    background: rgba(216, 216, 216, 0.25);
    border-radius: 20px;
}

.activity h3 {
    font-size: 2.3rem;
    font-weight: 300;
    font-family: "Lato", sans-serif;
    padding-left: 10px;
    text-decoration: underline;
    cursor: pointer;
} 

.activity-description {
    padding-left: 10px;
    font-size: 1.65rem;
    font-weight: 300;
    font-family: "Lato", sans-serif;
}

.activity-time-price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px 10px 30px;
    width: 100%;
}

.punto-separacion-time-price {
    font-size: 2rem;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    color: black;
    padding: 0 10px;
    position: relative;
    top: -2px;
}

.activity-time {
    background-color: white;
    border-radius: 20px;
    padding: 0 10px;
    color: rgb(133, 133, 133);
    font-family: "Lato", sans-serif;
}

.activity-price {
    color: black;
}

.salto-linea-activity {
    width: 95%;
    background: rgb(224 224 224);
}

.activity-directions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 25px 0 25px 20px;
    margin-left: 40px;
    border-left: 4px dashed rgb(224 224 224);
}

.direction-mode {
    margin-right: 7px;
}

.activity-directions p {
    margin: 0;
}

.activity-directions a {
    text-decoration: underline;
    color: black;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 1.55rem;
}

.activity-directions a:hover {
    cursor: pointer;
    transform: translateY(-3px);
}

.separacion-directions-enlace {
    padding: 0 10px 0 10px;
}

.container-btn-mostrarMapa {
    visibility: hidden;
}

.popup-content {
    width: 140px;
    height: 140px;
}

.activity-popup-description {
    position: absolute;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    padding: 10px;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .btn-bounce {
    animation: bounce 1s;
  }
  


@media (max-width: 1200px) {
    .container-left-specific-trip {
        width: 65%;
    }
    .container-right-specific-trip {
        width: 35%;
    }
}

@media (max-width: 1000px) {
    h2 {
        font-size: 2.4rem;
    }

    p {
        font-size: 1.65rem;
    }

    .container-left-specific-trip {
        width: 100%;
        border-right: none;
    }
    
    .container-right-specific-trip {
        display: none;
        width: 100%;
        z-index: 2;
    }

    .warning-map-error {
        width: 60%;
        left: 20%;
    }

    .izquierda-transport {
        width: 100%;
        border-right: none;
    }

    .derecha-accommodation {
        width: 100%;
    }

    .salto-linea-responsive {
        display: block;
    }
    
    .transport-accommodation {
        flex-direction: column;
    }

    .accommodation-avatares {
        width: 90%;
    }

    .image-container h2 {
        font-size: 2.3rem;
        font-family: "Lato", sans-serif;
        font-weight: 300;
        text-transform: none;
    }

    .activity h3 {
        font-size: 2rem;
    }

    .activity-description {
        font-size: 1.5rem;
    }

    .container-btn-mostrarMapa {
        visibility: visible;
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 2;
    }
    
    .btn-showMap {
        visibility: visible;
        padding: 20px;
        background-color: black;
        color: white;
        border-radius: 100px;
        border: none;
    }
    
    .btn-no-show-map {
        visibility: hidden;
    }

    .container-right-specific-trip.hide {
        transform: translateX(100%);
    }
    
    .container-right-specific-trip.show {
        transform: translateX(0);
    }
}