/* header.css adjustments */
#header .intro {
    display: flex;
    width: 100%;
    height: 100vh;
    text-align: center;
    color: #fff;
    background: url('./intro-bg2.png') center center no-repeat;
    background-size: cover;
  }
  
  #header .intro .overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: absolute;
  }
  
  #header .intro {
    margin-top: 0;
    padding: 0;
  }
  
  .intro h1 {
    font-family: "Lato", sans-serif;
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
    font-family: "Lato", sans-serif;
  }

  .intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 30px;
    font-family: "Lato", sans-serif;
  }

  header .intro-text {
    padding-top: 350px;
    padding-bottom: 200px;
    text-align: center;
    justify-content: center;
    text-align: center;
    font-family: "Lato", sans-serif;
  }
  
  @media (max-width: 768px) {
    #header .intro h1 {
      font-size: 62px;
    }
  
    #header .intro p {
      font-size: 18px;
    }
  }
  
  @media (max-width: 576px) {
    #header .intro h1 {
      font-size: 44px;
    }
  
    #header .intro p {
      font-size: 16px;
    }
  }
  