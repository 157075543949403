.overlay-transport {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que el overlay esté en el frente */
}

.overlay-transport-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 30px;
    width: 600px;
    height: 600px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#S517379 {
    width:500px;
    height:500px;
    border: none;
}

#S517617 {
    width:500px;
    height:500px;
    border: none;
}

.overlay-transport-close {
   position: absolute;
    bottom: 5%;
    cursor: pointer;
}

.overlay-transport-close i {
    background-color: white;
    padding: 15px;
    border-radius: 5%;
}

.overlay-transport-content h2 {
    margin-bottom: 20px;
}

.overlay-transport-content p {
    margin-bottom: 20px;
}

.overlay-transport-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.overlay-transport-content button:hover {
    background-color: #0056b3;
}

.no-scroll {
    overflow: hidden;
}

@media (max-width: 600px) {
    .overlay-transport-content {
        width: 400px;
        height: 550px;
    }

    #S517379 {
        width:300px;
        height:450px;
    }

    #S517617 {
        width:300px;
        height:450px;
    }

    .overlay-transport-close {
        bottom: 1%;
    }
}

@media (max-width: 400px) {
    .overlay-transport-content {
        width: 300px;
        height: 500px;
    }

    #S517379 {
        width:280px;
        height:400px;
    }

    #S517617 {
        width:280px;
        height:400px;
    }

    .overlay-transport-close {
        bottom: 1%;
    }
}

@media (max-width: 300px) {
    .overlay-transport-content {
        width: 250px;
        height: 450px;
    }

    #S517379 {
        width:230px;
        height:350px;
    }

    #S517617 {
        width:230px;
        height:350px;
    }

    .overlay-transport-close {
        bottom: 1%;
    }
}