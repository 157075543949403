.overlay-anuncio {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que el overlay esté en el frente */
}

.overlay-anuncio-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 30px;
    width: 600px;
    height: 600px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.overlay-anuncio-content > div {
    max-height: 600px;
    background: transparent;
}

.overlay-anuncio-content h1 {
    margin-bottom: 20px;
}

.overlay-anuncio-content p {
    margin-bottom: 20px;
}

.overlay-anuncio-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.overlay-anuncio-content button:hover {
    background-color: #0056b3;
}

.no-scroll {
    overflow: hidden;
}

@media (max-width: 600px) {
    .overlay-anuncio-content {
        width: 400px;
        height: 550px;
    }

    .overlay-anuncio-content > div {
        max-height: 400px;
        background: transparent;
    }
}

@media (max-width: 400px) {
    .overlay-anuncio-content {
        width: 300px;
        height: 500px;
    }
    .overlay-anuncio-content > div {
        max-height: 300px;
        background: transparent;
    }
}

@media (max-width: 300px) {
    .overlay-anuncio-content {
        width: 250px;
        height: 450px;
    }

    .overlay-anuncio-content > div {
        max-height: 200px;
        background: transparent;
    }
}
